import {
  Card,
  CardContent,
  Chip,
  Divider,
  Typography,
} from "@mui/material";
import { blue, red } from "@mui/material/colors";
import { Box } from "@mui/system";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db, user_profile } from "../auth/firebase";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
// import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

function History() {
  const [render_datas, setRenderDatas] = useState(null);

  useEffect(() => {
    const q = query(
      collection(db, "datas"),
      where("userid", "==", user_profile.uid),
      orderBy("createdAt", "desc")
    );

    onSnapshot(q, (querySnapshot) => {
      let hisotry_datas = [];
      querySnapshot.forEach((doc) => {
        const createdAt = String(doc.data().createdAt.toDate());
        const amount = String(doc.data().amount);
        const amountBetween = String(doc.data().amountBetween);
        hisotry_datas.push({
          createdAt: createdAt,
          amount: amount,
          amount_bet: amountBetween,
        });
      });
      setRenderDatas(hisotry_datas);
    });
  }, []);

  const HistoryCompo = () => {
    return render_datas !== null ? (
      render_datas.map((render_data, index) => (
        <div key={index}>
          <Card sx={{ margin: "8px" }}>
            <CardContent>
              <Typography variant={"subtitle1"} color={"black"}>
                {render_data.createdAt}
              </Typography>
              <Divider />
              <Box display={"flex"} alignItems="center" padding={1}>
                <Chip
                  label={render_data.amount_bet < 0 ? "Remove" : "Add"}
                  variant="outlined"
                  sx={{ marginRight: 2 }}
                  color={render_data.amount_bet < 0 ? "error" : "primary"}
                  // icon={render_data.amount_bet < 0 ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                />
                <Typography
                  variant={"h5"}
                  color={render_data.amount_bet < 0 ? red[500] : blue[500]}
                >
                  {render_data.amount_bet}
                </Typography>
                <ArrowRightAltIcon sx={{ marginLeft: 2 }} />
                <Typography sx={{ marginLeft: 2 }} variant={"h5"}>
                  {render_data.amount}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </div>
      ))
    ) : (
      <div></div>
    );
  };

  return (
    <div>
      <HistoryCompo />
    </div>
  );
}

export default History;
