import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCfFhQ_Fzo52iOD2rzLuib0MkUOzoHPzoo",
  authDomain: "practice-project-cb8cb.firebaseapp.com",
  projectId: "practice-project-cb8cb",
  storageBucket: "practice-project-cb8cb.appspot.com",
  messagingSenderId: "431354754088",
  appId: "1:431354754088:web:f5e8fe44c1a0eab9b3f11d",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

var user_profile = {};

onAuthStateChanged(auth, (user) => {
  if (user) {
    user_profile["email"] = user.email;
    user_profile["displayname"] = user.displayName;
    user_profile["uid"] = user.uid;
  }
});

export { db, auth, user_profile };
