import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { blue, red } from "@mui/material/colors";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import { db, user_profile } from "../auth/firebase";
import {
  addDoc,
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { useState } from "react";

function Home() {
  const [amountnow, setAmountnow] = useState(0);
  const [addValue, setAddvalue] = useState(0);
  const [removeValue, setRemovevalue] = useState(0);

  // --------------------------------------------------------

  async function send_datas(amountnow_bet) {
    const amount_value = Number(amountnow) + amountnow_bet;
    await addDoc(collection(db, "datas"), {
      userid: user_profile.uid,
      displayname: user_profile.displayname,
      createdAt: Timestamp.now(),
      amount: amount_value,
      amountBetween: amountnow_bet,
    });
  }
  const q = query(
    collection(db, "datas"),
    where("userid", "==", user_profile.uid),
    orderBy("createdAt", "desc"),
    limit(1)
  );
  onSnapshot(q, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      setAmountnow(doc.data().amount);
    });
  });

  // --------------------------------------------------------

  const [openAdd, setOpenAdd] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);

  function handleClickOpen(a_r) {
    if (a_r === "add") {
      setOpenAdd(true);
    } else if (a_r === "remove") {
      setOpenRemove(true);
    }
  }

  const handleClose = (a_r) => {
    if (a_r === "add") {
      setOpenAdd(false);
    } else if (a_r === "remove") {
      setOpenRemove(false);
    }
  };

  function addValueSubscribe() {
    send_datas(addValue);
    setOpenAdd(false);
  }

  function removeValueSubscribe() {
    send_datas(removeValue);
    setOpenRemove(false);
  }

  // --------------------------------------------------------

  return (
    <div>
      <Box>
        <Grid container alignItems={"center"} spacing={2}>
          <Grid item width={"100%"}>
            <Card
              sx={{ height: "240px", textAlign: "center", padding: "24px" }}
            >
              <Typography variant="h5">Amount now</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Typography variant="h2" color={amountnow < 0 ? red[500] : blue[500]} fontWeight={"bold"}>
                  {amountnow}
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item width={"50%"}>
            <Card sx={{ height: "160px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <IconButton
                  onClick={() => {
                    handleClickOpen("add");
                  }}
                >
                  <AddCircleOutlineIcon
                    style={{ color: blue[500], fontSize: "48px" }}
                  />
                </IconButton>
              </Box>
            </Card>
          </Grid>
          <Grid item width={"50%"}>
            <Card sx={{ height: "160px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <IconButton
                  onClick={() => {
                    handleClickOpen("remove");
                  }}
                >
                  <RemoveCircleOutlineIcon
                    style={{ color: red[500], fontSize: "48px" }}
                  />
                </IconButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={openAdd}
        onClose={() => {
          handleClose("add");
        }}
      >
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>How much do you add?</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Value"
            type="number"
            fullWidth
            variant="standard"
            onChange={(event) => setAddvalue(Number(event.target.value))}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose("add");
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              addValueSubscribe();
            }}
          >
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openRemove}
        onClose={() => {
          handleClose("remove");
        }}
      >
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>How much do you remove?</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Value"
            type="number"
            fullWidth
            variant="standard"
            onChange={(event) =>
              setRemovevalue(Number(event.target.value) * -1)
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose("remove");
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              removeValueSubscribe();
            }}
          >
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Home;
