import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { auth, user_profile } from "../auth/firebase";

function Profile() {
  return (
    <div>
      <Box width={"100%"} textAlign="center" m={2}>
        <Typography variant="h4">{user_profile.displayname}</Typography>
        <Button
          variant="outlined"
          sx={{ margin: 4 }}
          onClick={() => {
            auth.signOut();
          }}
        >
          Sign out
        </Button>
      </Box>
    </div>
  );
}

export default Profile;
