import { Button, Card, Typography } from "@mui/material";
import React from "react";

import "../../css/signin.css";

import GoogleIcon from "@mui/icons-material/Google";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "./firebase";

function SignIn() {
  function SignInWithGoogle() {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  }
  return (
    <div>
      <div className="signin_wrapper">
        <Card sx={{ padding: 2, margin: 2 }} variant="outlined">
          <Typography variant="body1" margin={2} color={"text.secondary"}>
            Sign in with Google.
          </Typography>
          <Button
            onClick={SignInWithGoogle}
            variant="outlined"
            endIcon={<GoogleIcon />}
          >
            Sign In
          </Button>
        </Card>
      </div>
      <Typography sx={{textAlign: "center"}} color={"text.secondary"}>We are not responsible for any data entered into this site.</Typography>
    </div>
  );
}

export default SignIn;
