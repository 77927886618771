import { Typography } from "@mui/material";
import React from "react";

function Header4notlogin() {
  return (
    <div>
      <header>
        <Typography variant="h6">Easy cash book</Typography>
      </header>
    </div>
  );
}

export default Header4notlogin;
