import SignIn from "./components/auth/SignIn";
import Header4login from "./components/Header4login";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./components/auth/firebase";
import Main from "./components/Main";
import Header4notlogin from "./components/Header4notlogin";
import { BrowserRouter } from "react-router-dom";

function App() {
  const [user] = useAuthState(auth);
  return (
    <BrowserRouter>
      <div className="App">
        {user ? <Header4login /> : <Header4notlogin />}
        {user ? <Main /> : <SignIn />}
      </div>
    </BrowserRouter>
  );
}

export default App;
