import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function Nav() {
  return (
    <div>
      <Box>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"flex-end"}
          direction={"column"}
          spacing={1}
        >
          <Grid item>
            <Link to={"/"}>
              <Typography variant="h5" fontWeight={"bold"}>
                Home
              </Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link to={"/history"}>
              <Typography variant="h5" fontWeight={"bold"}>
                History
              </Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link to={"/profile"}>
              <Typography variant="h5" fontWeight={"bold"}>
                Profile
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Nav;
