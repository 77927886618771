import { Box } from "@mui/material";
import React from "react";
import Nav from "./Nav";
import Home from "./routes/Home";

import { Routes, Route } from "react-router-dom";
import History from "./routes/History";
import Profile from "./routes/Profile";

function Main() {
  return (
    <div>
      <Box display={"flex"} maxWidth={1200} margin={"0 auto"} paddingTop={"56px"}>
        <Box
          sx={{ width: 200, height: 560, position: "fixed", padding: "56px 0" }}
        >
          <Nav />
        </Box>
        <Box width={1000} marginLeft={"200px"} padding={"56px"}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/history" element={<History />} />
            <Route path="/profile" element={<Profile />} />
          </Routes>
        </Box>
      </Box>
    </div>
  );
}

export default Main;
